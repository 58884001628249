// @flow
import React, {FunctionComponent} from 'react';
import {Box, Button, MenuItem, TextField} from "@mui/material";
import ReactHookFormSelect from "../Common/react-hook-form-select";
import {AnimalIdentifier, AnimalIdentifierTypeValues} from "../../api/generated/herd-animal";
import {useForm} from "react-hook-form";
import SaveIcon from "@mui/icons-material/Save";
import FlexStack from '../Common/flex-stack';

type AnimalIdFormProps = {
    initialData?: AnimalIdentifier;
    onSubmit: (original: AnimalIdentifier | undefined, updated: AnimalIdentifier) => void;
    onCancelClick?: () => void;
    pending?: boolean;
};

const requiredFieldConfig = {required: {value: true, message: "Pflichtfeld"}} //TODO get i18n to work

const AnimalIdForm: FunctionComponent<AnimalIdFormProps> = ({initialData, onSubmit, onCancelClick, pending}) => {
    const initialAnimalIdentifier: AnimalIdentifier = initialData ?? {
        position: "left",
        type: AnimalIdentifierTypeValues.CHIP,
        value: ""
    }

    const {
        formState: {errors},
        register,
        handleSubmit,
        control
    } = useForm<AnimalIdentifier>({defaultValues: initialAnimalIdentifier})

    return (
        <Box>
            <form onSubmit={handleSubmit((ai) => onSubmit(initialData, ai))} autoComplete="off" noValidate>
                <FlexStack>
                    <TextField label="Chip/Ohrmarke Nr." required
                               {...register("value", requiredFieldConfig)} error={!!errors.value}
                               helperText={errors.value?.message ?? ''}/>
                    <TextField label="Position" required
                               {...register("position", requiredFieldConfig)} error={!!errors.position}
                               helperText={errors.position?.message ?? ''}/>
                    <ReactHookFormSelect name={"type"} label={"Typ"} control={control}>
                        {Object.keys(AnimalIdentifierTypeValues).map((val: string) => <MenuItem key={val}
                                                                                                value={val}>{val}</MenuItem>)}
                    </ReactHookFormSelect>
                    <Button
                        className="btn-submit-identifier"
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={pending}
                        startIcon={<SaveIcon/>}
                    >
                        Speichern
                    </Button>
                    {onCancelClick && <Button
                        className="btn-create-animal"
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={onCancelClick}
                    >
                        Abbrechen
                    </Button>
                    }
                </FlexStack>
            </form>

        </Box>
    );
};

export default AnimalIdForm