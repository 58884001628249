import FlexStack from "../components/Common/flex-stack";
import {
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import React, {useMemo} from "react";
import {Widget} from "../components/Common";
import {useDocumentDeletion, useDocuments} from "../hooks/use-documents";
import {Delete} from "@mui/icons-material";
import {InfoBox} from "../components/Common/alerts";
import {DocumentDetailsJson} from "../api/generated/herds-documents";
import FormattedDate from "../components/Common/formatted-date";
import {DocumentService} from "../services/document-service";
import AuthService from "../services/auth-service";
import * as Roles from "../components/Permissions/Roles";

const DocumentRow = ({document}: { document: DocumentDetailsJson }) => {
    const {delete: deleteDocument, isDeleting} = useDocumentDeletion();

    const handleDeleteClick = async (id: string) => {
        if (window.confirm(`Dokument ${document.filename} (${document.title}) löschen? Diese Aktion kann nicht rückgängig gemacht werden`)) {
            void deleteDocument(id);
        }
    };

    const handleClick = async (ev: React.MouseEvent) => {
        ev.preventDefault();

        // open window before first await, so it is not blocked by browsers/ad-blockers:
        const w = window.open();
        if (!w) {
            return;
        }

        const blob = await DocumentService.download(document.id);
        const href = DocumentService.generateBlobUrl(blob);

        try {
            w.location = href;
        } finally {
            DocumentService.releaseBlobUrl(href);
        }
    };

    return (
        <TableRow key={document.id} hover>
            <TableCell>
                <Link href='#' target="_blank" onClick={handleClick}>{document.filename}</Link>
            </TableCell>
            <TableCell><FormattedDate sx={{}} date={document.uploadedAt}/></TableCell>
            <TableCell>{document.title}</TableCell>
            <TableCell>{document.note}</TableCell>
            <TableCell>
                {AuthService.hasRole([Roles.PANON_ADMIN]) && (
                    <IconButton aria-label="delete" disabled={isDeleting} onClick={() => handleDeleteClick(document.id)}>
                        <Delete/>
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
};

export const DocumentsListPage = () => {
    const {documents} = useDocuments();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const documentsOfPage = useMemo(
        () => documents.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
        [documents, page, rowsPerPage]);

    if (!documents.length) {
        return (
            <FlexStack>
                <Widget displayName="Dokumente und Dateien">
                    <InfoBox>Keine Dokumente vorhanden</InfoBox>
                </Widget>
            </FlexStack>
        );
    }

    const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <FlexStack>
            <Widget displayName={`Dokumente und Dateien (${documents.length})`}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/*<TableCell>Vorschau</TableCell>*/}
                                <TableCell>Datei</TableCell>
                                <TableCell>Datum</TableCell>
                                <TableCell>Titel</TableCell>
                                <TableCell>Notiz</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documentsOfPage.map(d => <DocumentRow key={d.id} document={d}/>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]}
                    count={documents.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
            </Widget>
        </FlexStack>
    );
};
