// @flow
import React, {FunctionComponent, useState} from 'react';
import {Widget} from "../Common";
import {AnimalIdentifier} from "../../api/generated/herd-animal";
import {IconButton, Link, Table, TableCell, TableHead, TableRow} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import AnimalIdForm from "../Animal/animal-id-form";
import {useGlobalSnackbarStore} from "../../stores/global-snackbar-store";
import {AnimalService} from "../../services/animal-service";
import {JsonAnimal} from "../../api/generated/rest-dto";
import DeleteIcon from "@mui/icons-material/Delete";
import {getErrorMessage} from "../../errors/error-utils";
import {useTranslation} from "react-i18next";
import {InfoBox} from '../Common/alerts';
import {useMutation, useQueryClient} from "@tanstack/react-query";

interface IdentificationsWidgetProps {
    /* We only allow editing of existing identifications by owner for unregistered animals. However, new identifications
    * can be added any time, so we don't have a flag for that. */
    editExistingAllowed?: boolean
    animal: JsonAnimal
}

const useInvalidateAnimalQueries = () => {
    const queryClient = useQueryClient();
    return async () => await Promise.all([
        queryClient.invalidateQueries({queryKey: ['animals']}),
        queryClient.invalidateQueries({queryKey: ['animal']}),
    ]);
};

const IdentificationsTableRow = ({animal, identifier, editExistingAllowed, openIdForm}: IdentificationsWidgetProps & {
    identifier: AnimalIdentifier,
    openIdForm: (animalIdentifier: AnimalIdentifier | undefined) => void,
}) => {
    const {t} = useTranslation();
    const {error, success} = useGlobalSnackbarStore((state) => state);
    const invalidateQueries = useInvalidateAnimalQueries();

    const {mutateAsync: handleDelete, isPending: isDeleting} = useMutation({
        mutationFn: async (animalIdentifier: AnimalIdentifier) => await AnimalService.deleteAnimalIdentifier(animal, animalIdentifier),
        onSuccess: () => success('Erfolgreich gelöscht'),
        onError: (e) => error(getErrorMessage(e)),
        onSettled: invalidateQueries,
    });

    return (
        <TableRow key={JSON.stringify([identifier.value, identifier.type, identifier.position])}>
            <TableCell>{identifier.value}</TableCell>
            <TableCell>{t('identifier.type.' + identifier.type)}</TableCell>
            <TableCell>{identifier.position}</TableCell>
            {!!editExistingAllowed &&
                <TableCell>
                    <IconButton onClick={() => openIdForm(identifier)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(identifier)} disabled={isDeleting}>
                        <DeleteIcon/>
                    </IconButton>
                </TableCell>
            }
        </TableRow>
    );
};

const IdentificationsTable = ({animal, editExistingAllowed, openIdForm}: {
    animal: JsonAnimal,
    editExistingAllowed?: boolean,
    openIdForm: (animalIdentifier: AnimalIdentifier | undefined) => void,
}) => {
    if (!animal.panonIdentifier.animalIdentifiers.length) {
        return (
            <InfoBox>
                Keine Identifikationsnummern vorhanden. <Link href="#" onClick={(ev) => {
                    ev.preventDefault();
                    openIdForm(undefined);
                    return false;
                }}>Jetzt hinzufügen</Link>
            </InfoBox>
        );
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Nummer</TableCell>
                    <TableCell>Typ</TableCell>
                    <TableCell>Position</TableCell>
                    {!!editExistingAllowed && <TableCell>Bearbeiten</TableCell>}
                </TableRow>
            </TableHead>
            {animal.panonIdentifier.animalIdentifiers.map(ai => (
                <IdentificationsTableRow
                    key={JSON.stringify([ai.value, ai.type, ai.position])}
                    animal={animal}
                    identifier={ai}
                    openIdForm={openIdForm}
                    editExistingAllowed={editExistingAllowed}/>
            ))}
        </Table>
    );
};

const IdentificationsWidget: FunctionComponent<IdentificationsWidgetProps> = ({
                                                                                  animal,
                                                                                  editExistingAllowed = false
                                                                              }) => {

    const {error, success} = useGlobalSnackbarStore((state) => state);
    const [initialIdFormData, setInitialIdFormData] = useState<AnimalIdentifier | undefined>(undefined)

    const [addOpen, setAddOpen] = useState(false);
    const invalidateQueries = useInvalidateAnimalQueries();

    const { mutateAsync: submit, isPending: isSaving } = useMutation({
        mutationFn: async ({original, updated}: {
            original: AnimalIdentifier | undefined,
            updated: AnimalIdentifier
        }) => {
            if (original) {
                if (!editExistingAllowed && AnimalService.hasAnimalIdentifier(animal, original)) {
                    error('Bestehende Identifikationsnummern können nur vom Zuchtwart oder einem Adiminstrator geändert werden!');
                    return Promise.reject();
                }
                return await AnimalService.updateAnimalIdentifier(animal, original, updated);
            } else {
                return await AnimalService.addAnimalIdentifier(animal, updated);
            }
        },
        onSuccess: () => {
            success('Identifikationsnummer gespeichert');
            setAddOpen(false);
        },
        onError: (e) => error(getErrorMessage(e)),
        onSettled: invalidateQueries,
    });

    const openIdForm = (initialData: AnimalIdentifier | undefined) => {
        setInitialIdFormData(initialData);
        setAddOpen(true);
    }

    const handleSubmit = async (original: AnimalIdentifier | undefined, updated: AnimalIdentifier) => submit({
        original,
        updated
    });

    return (
        <Widget displayName="Identifikationsnummern" cardMenuEntries={[{
            actionName: "Hinzufügen",
            actionFunction: () => openIdForm(undefined)
        }]}>
            {!addOpen
                ? <IdentificationsTable
                    animal={animal}
                    editExistingAllowed={editExistingAllowed}
                    openIdForm={openIdForm}/>
                : <AnimalIdForm
                    initialData={initialIdFormData}
                    onCancelClick={() => setAddOpen(false)}
                    onSubmit={handleSubmit}
                    pending={isSaving}/>
            }

        </Widget>
    );
};

export default IdentificationsWidget