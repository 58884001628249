import {JsonAnimal} from "../api/generated/rest-dto";
import {useBirthStats} from "../components/AnimalDashboard/Pregnancy/hooks/use-birth-stats";
import {Button, ButtonGroup, createTheme, ThemeProvider} from "@mui/material";
import React from "react";
import {LoadingSuspense} from "../components/Common/loading-suspense";

const theme = createTheme({
    typography: {
        button: {
            textTransform: 'none'
        },
    },
});

type Props = {
    dam: JsonAnimal,
    onPick: (days: number) => void,
    days?: number,
    defaultDuration: number,
};

const PregnancyDurationPickerButtons = ({dam, onPick, defaultDuration, days}: Props) => {
    const {stats} = useBirthStats(dam);

    if (!stats.count) {
        return <></>;
    }

    const buttons = [
        { days: defaultDuration, text: 'Standard' },
        { days: stats.average, text: 'Durchschnitt' },
        { days: stats.last, text: 'Letzte' },
    ];

    return (
        <ThemeProvider theme={theme}>
            <ButtonGroup variant="outlined">
                {buttons.map((btn, idx) => (
                    <Button key={idx} size="small" variant={btn.days === days ? 'contained' : undefined} onClick={() => onPick(btn.days)}>
                        {btn.text} ({btn.days})
                    </Button>
                ))}
            </ButtonGroup>
        </ThemeProvider>
    );
};

export const PregnancyDurationPicker = (props: Props) => (
    <LoadingSuspense>
        <PregnancyDurationPickerButtons {...props}/>
    </LoadingSuspense>
);