import React, {PropsWithChildren, ReactNode, Suspense} from "react";
import {LoadingFallback} from "./loading-fallback";
import {ErrorBoundary} from "react-error-boundary";
import { ErrorBox } from "./alerts";

export const LoadingSuspense = (props: PropsWithChildren<{ fallback?: ReactNode, error?: ReactNode }>) => (
    <ErrorBoundary fallback={props.error ?? <ErrorBox>Fehler</ErrorBox>}>
        <Suspense fallback={props.fallback ?? <LoadingFallback/>}>
            {props.children}
        </Suspense>
    </ErrorBoundary>
);