import {JsonAnimal} from "../../../../api/generated/rest-dto";
import {useSuspenseQuery} from "@tanstack/react-query";
import {PregnancyService} from "../../../../services/pregnancy-service";

export const useBirthStats = (dam: Pick<JsonAnimal, 'panonIdentifier'>) => {
    const queryInfo = useSuspenseQuery({
        queryKey: ['pregnancy', 'stats'],
        queryFn: async () => await PregnancyService.loadStats(dam.panonIdentifier.id),
    });
    return {
        ...queryInfo,
        stats: queryInfo.data,
    };
};